import { toValue } from '@vueuse/core';
import { computed } from 'vue';
import { useRoute } from 'vue-router/composables';
import { useMutation } from '@/helpers/composables/useApollo';

import { Platform, SessionEnvironmentContextInput, SraEventAction } from '@/@types/graphql-types';
import { ApplicationContext as applicationContext, TrackingAppId as appId } from '@/helpers/tracking/tracking-helper';
import { getUtmSearchParams } from '@/helpers/composables/useArbitrage';

import { useLanguageStore, useRoutingStore, useTrackingStore } from '@/helpers/composables/useStores';

import {
	TrackSraEventDocument,
	TrackSraEventMutation,
	TrackSraEventMutationVariables,
} from '@/graphql/mutation/TrackSraEvent.mutation';
import type { MaybeRefOrGetter } from '@vueuse/core';
import { captureMessageForSentry } from '@/helpers/sentry-helper';
import { SponsoredAdFragment } from '@/pages/graphql/fragments/SponsoredAd.fragment';
import { SessionEnvironment } from '@/stores/tracking.store';

interface TrackSraArguments {
	action: SraEventAction;
	sponsoredAd: MaybeRefOrGetter<SponsoredAdFragment | undefined>;
	jwEntityID?: string;
	position?: number;
	progress?: number;
	isSrTampered?: boolean;
}

export type TrackSraEvent = ReturnType<typeof useBackendTracking>['trackSraEvent'];

const DOMAIN = `https://www.${JW_CONFIG.DOMAIN}` as const;

export function useBackendTracking() {
	const route = useRoute();
	const trackingStore = useTrackingStore();
	const { currentPageType } = useRoutingStore();
	const { country, language } = useLanguageStore();

	const pageUrl = computed(() => {
		const params = getUtmSearchParams();
		const url = new URL(route.fullPath, DOMAIN);

		if (params) params.forEach((value, key) => url.searchParams.set(key, value));

		return url.href;
	});

	const sessionEnvironmentContext = computed<SessionEnvironmentContextInput>(() => {
		const {
			// fields not in mutation
			sessionReady,
			originRefrSocial,

			// fields to rename
			hasAdBlocker: hasAdblocker,
			originPageUrl: originPageURL,

			...sessionEnvironment
		} = trackingStore.sessionEnvironment.value as SessionEnvironment;

		void sessionReady;
		void originRefrSocial;

		return { ...sessionEnvironment, hasAdblocker, originPageURL };
	});

	function trackSraEvent({ action, sponsoredAd, jwEntityID, position, progress, isSrTampered }: TrackSraArguments) {
		const bidId = toValue(sponsoredAd)?.bidId;
		const isShadowEvent = toValue(sponsoredAd)?.campaign == null;
		const campaignName = toValue(sponsoredAd)?.campaign?.name;

		const debuggingInfo = computed(() => {
			return {
				action,
				campaignName,
				sponsoredAd: JSON.stringify(toValue(sponsoredAd)),
				pageType: currentPageType.value,
				jwEntityID,
			};
		});

		if (!bidId) {
			captureMessageForSentry(
				'[Bid ID is empty]:',
				{
					where: 'Composable: useBackendTracking',
					mutationInfo: debuggingInfo,
				},
				'error',
				{ SR: campaignName ?? '' }
			);
		}

		if (currentPageType.value === null) {
			captureMessageForSentry(
				'[Page Type is empty]:',
				{
					where: 'Composable: useBackendTracking',
					mutationInfo: {
						action,
						currentPageType: currentPageType.value,
					},
				},
				'error',
				{ SR: campaignName ?? '' }
			);
		}

		const { mutate, onError } = useMutation<TrackSraEventMutation, TrackSraEventMutationVariables>(
			TrackSraEventDocument
		);

		if (bidId) {
			mutate({
				input: {
					bidId,
					action,
					sraInput: {
						appId,
						applicationContext,
						country: country.value,
						language: language.value,
						pageType: currentPageType.value,
						platform: Platform.Web,
						sessionEnvironmentContext: sessionEnvironmentContext.value,
					},
					jwEntityID,
					position,
					progress,
					pageUrl: pageUrl.value,
					isShadowEvent,
					isSrTampered,
				},
			});
		}

		onError(error =>
			captureMessageForSentry(
				'[GraphQL Track SRA Event]:',
				{
					error,
					where: 'Composable: useBackendTracking',
					mutationInfo: {
						...debuggingInfo,
						position,
						progress,
					},
				},
				'error',
				{ SR: campaignName ?? '' }
			)
		);
	}
	return { trackSraEvent };
}
